import { useState } from 'react';
import Logo from '../src/imgs/logo.png'
import { Card } from './components/Card';

const cardsData = [
  { id: 1, 
    title: 'Youtube', 
    content: [
      {type:'text',content:'lorem ipsumlorem ipsumlorem ipsum'},
      {type:'text',content:'lorem ipsumlorem ipsumlorem ipsum'},
      {type:'img',content:Logo},
      {type:'btn',content:'https://www.youtube.com/@Alfinentendi/', }
    ] 
  },
  { id: 2, 
    title: 'Libro', 
    content: [
      {type:'text',content:'lorem ipsumlorem ipsumlorem ipsum'},
      {type:'text',content:'lorem ipsumlorem ipsumlorem ipsum'},
      {type:'video',content:'https://www.youtube.com/watch?v=PPpUUbfMTko'}
    ] 
  },
  { id: 3, 
    title: 'kofi', 
    content: [
      {type:'text',content:'lorem ipsumlorem ipsumlorem ipsum'},
      {type:'text',content:'lorem ipsumlorem ipsumlorem ipsum'},
      {type:'btn',content:'https://sass-lang.com/documentation/variables/', buttonText: 'Visitar sitio'}
    ] 
  },
  { id: 4, 
    title: 'kahoot', 
    content: [
      {type:'text',content:'lorem ipsumlorem ipsumlorem ipsum'},
      {type:'text',content:'lorem ipsumlorem ipsumlorem ipsum'},
      {type:'btn',content:'https://sass-lang.com/documentation/variables/', buttonText: 'Visitar sitio'}
    ] 
  },
  // Agrega más datos de cards aquí
];

function App() {
  const [activeCards, setActiveCards] = useState([]);

  const handleCardClick = (id)=> {
    if (activeCards.includes(id)) {
      setActiveCards(activeCards.filter((cardId) => cardId !== id));
    } else {
      setActiveCards([...activeCards, id]);
    }
  }
  return (
    <div className='app'>
      <header className={`${activeCards.length > 0 ? 'active' : ''}`}>
        <img src={Logo} alt="Al Fin Entendi Logo" />
        <h2>Al Fin Entendī</h2> 
        <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Minima sunt nobis corporis </p>
      </header>
      <main>
        {
          cardsData.map((card) => (
            <Card 
            key={card.id}
            id={card.id}
            title={card.title}
            content={card.content}
            isActive={activeCards.includes(card.id)}
            onCardClick={handleCardClick}
            />
          ))
        }
       

      </main>
      <footer>
        <div className="links">
          <div className="icon"><a target='_blank' rel="noreferrer" href="https://www.facebook.com/alfinentendi"><i className="fa-brands fa-facebook"></i></a></div>
          {/* <div className="icon"><a target='_blank' rel="noreferrer" href=""><i className="fa-brands fa-twitter"></i></a></div> */}
          <div className="icon"><a target='_blank' rel="noreferrer" href="mailto:alfinentendi@gmail.com"><i className="fa-solid fa-envelope"></i></a></div>
          <div className="icon"><a target='_blank' rel="noreferrer" href="https://www.instagram.com/al_fin_entendi/"><i className="fa-brands fa-instagram"></i></a></div>
        </div>

      </footer>
    </div>
  );
}

export default App;
